import React, { useRef } from "react";

import Box from "./Box";
import Button from "./Button";
import Link from "next/link";
import LinkButtonSecondary from "./LinkButtonSecondary";
import PropTypes from "prop-types";
import SquareImage from "./SquareImage";
import Text from "./Text";
import { useHovered } from "../core/hooks";
import { ACTIVE } from "../core/constants";

function BrandPromoImage({ src, alt, hovered }) {
  return (
    <Box
      transition="transform 300ms ease-out"
      transform={hovered ? "scale3d(1.05,1.05,1.05)" : "scale3d(1,1,1)"}
    >
      <SquareImage
        src={src}
        alt={alt}
        sizes="(max-width: 640px) 70vw, (max-width: 960px) 40vw, 25vw"
      />
    </Box>
  );
}

function BrandLink({ brand }) {
  const ref = useRef(null);
  const hovered = useHovered(ref);

  const href = `/${brand.slug}`;

  return (
    <Box>
      <Text
        fontSize={[16, 16, 16, 16]}
        uppercase
        fontWeight={400}
        fontFamily="ibmPlexMono"
        mb="12px"
      >
        {brand.name}
      </Text>
      <Box position="relative" ref={ref} height={0} pb="100%" overflow="hidden">
        {brand.shopVisibility === ACTIVE ? (
          <Link href={href} passHref>
            <Button as="a" position="absolute" height="100%" width="100%">
              <BrandPromoImage
                src={brand.brandsPromoImage.url}
                alt={brand.name}
                hovered={hovered}
              />
            </Button>
          </Link>
        ) : (
          <BrandPromoImage
            src={brand.brandsPromoImage.url}
            alt={brand.name}
            hovered={false}
          />
        )}

        <Box
          position="absolute"
          bottom={0}
          left={0}
          display="flex"
          alignItems="flex-end"
          zIndex={2}
          // Prevents visual glitch on mobile during animation
          transform="translate3d(0,0,0)"
        >
          {brand.shopVisibility === ACTIVE && (
            <LinkButtonSecondary
              color="white"
              bg="black"
              text="Shop"
              href={href}
            />
          )}
          {brand.takebackUrl && (
            <LinkButtonSecondary
              color="white"
              bg="black"
              text="Takeback"
              href={brand.takebackUrl}
              external
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default BrandLink;

BrandLink.propTypes = {
  brand: PropTypes.shape({
    slug: PropTypes.string,
    name: PropTypes.string.isRequired,
    takebackUrl: PropTypes.string,
    brandsPromoImage: PropTypes.shape({ url: PropTypes.string.isRequired })
      .isRequired,
  }).isRequired,
};
