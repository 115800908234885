import Head from "next/head";
import HomePage from "../components/HomePage";
import Metatags from "../components/Metatags";
import PropTypes from "prop-types";
import { getBrandPromos } from "../core/api/brands";
import { getMenu } from "../core/api/menu";
import { getFooter } from "../core/api/core";
import { getHomePage } from "../core/api/home";
import { getLatestDropPromo } from "../core/api/latestDrop";

export default function Home({
  data,
  menuData,
  brands,
  globalData,
  latestDrop,
}) {
  return (
    <>
      <Head>
        <title>Reskinned Clothing | Pre-loved Fashion</title>
      </Head>
      <Metatags
        metaTitle={data.wagtail.homePage.metaTitle}
        metaDescription={data.wagtail.homePage.metaDescription}
      />
      <HomePage
        pageData={data.wagtail.homePage}
        brands={brands}
        latestDrop={latestDrop}
        navItems={menuData}
        globalData={globalData}
      />
    </>
  );
}

Home.propTypes = {
  data: PropTypes.object.isRequired,
  menuData: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  globalData: PropTypes.object.isRequired,
};

export async function getStaticProps() {
  const { data } = await getHomePage();

  const latestDrop = await getLatestDropPromo(
    data.wagtail.homePage.latestDropBrand
      ? data.wagtail.homePage.latestDropBrand.slug
      : null
  );

  const brands = await getBrandPromos(16);
  const globalData = await getFooter();

  const menuData = await getMenu();

  return {
    props: {
      data,
      menuData,
      latestDrop,
      brands: brands,
      globalData,
    },
    revalidate: 300,
  };
}
//
